import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { TooltipWithPopper } from '@ee-monorepo/prismic/shared/ui';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import cn from 'classnames';
import { RichTextField } from '@prismicio/types';
import { richTextSerializer } from '@ee-monorepo/prismic/shared/serializers';
import { PrismicRichText } from '@prismicio/react';
import { SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import { colors } from '@snap/colors';

/* eslint-disable-next-line */
export interface StoryWithoutImageProps extends PrismicFields.SliceBase {
  slice: {
    primary: {
      title?: PrismicFields.RichTextField;
      background_color?: PrismicFields.Select;
      title_text_color?: PrismicFields.Select;
      percentage_color?: PrismicFields.Select;
    };
    items?: {
      percentage_number?: PrismicFields.KeyTextField;
      percentage_text_color?: PrismicFields.Select;
      description?: PrismicFields.RichTextField;
      description_text_color?: PrismicFields.Select;
      show_disclaimer?: boolean;
      disclaimer_text?: PrismicFields.RichTextField;
    }[];
  };
}

export function StoryWithoutImage(props: StoryWithoutImageProps) {
  const { title, background_color, title_text_color, percentage_color } =
    props.slice.primary;
  const { items } = props.slice;
  return (
    <div
      className={cn(
        background_color ? getColor(background_color).backgroundColorClass : ''
      )}
    >
      <SliceWrapper
        data-testid="storyWithoutImage-slice"
        className={cn('snap-container sm:flex-col my-0', 'py-12')}
      >
        <div
          data-testid="storyContainer"
          className="sm:text-left md:text-center text-center px-4"
        >
          <div
            data-testid="story-title"
            className={cn(
              'headingL md:headingXL',
              'mb-4',
              title_text_color
                ? getColor(title_text_color).textColorClass
                : 'text-SnapGreen'
            )}
          >
            {title && title.length > 0 ? (
              <PrismicRichText field={title} />
            ) : null}
          </div>
          {items &&
            items.length > 0 &&
            items?.map((item, index) => {
              const {
                percentage_number,
                description,
                description_text_color,
                show_disclaimer,
                disclaimer_text,
              } = item;
              return (
                <div key={index} data-testid="repeatable-item">
                  <div className="flex sm:justify-start md:justify-center justify-center">
                    <div
                      data-testid="story-title"
                      className={cn(
                        'bodyFont md:headingM text-SnapGrey500',
                        'flex [&_*]:headingMHeavy',
                        description_text_color
                          ? getColor(description_text_color).textColorClass
                          : 'text-SnapGreen'
                      )}
                    >
                      {percentage_number ? (
                        <span
                          className={cn(
                            'mr-2',
                            percentage_color
                              ? getColor(percentage_color).textColorClass
                              : 'text-SnapGreen'
                          )}
                        >
                          {percentage_number}
                        </span>
                      ) : null}
                      {show_disclaimer &&
                      description &&
                      description.length > 0 &&
                      disclaimer_text &&
                      disclaimer_text.length > 0 ? (
                        <span className="[&_p]:headingMHeavy [&_p]:xs-text-xs [&_p]:sm-text-xs">
                          <TooltipWithPopper
                            mainContent={description as RichTextField}
                            mainContentSerializer={richTextSerializer}
                            popperContent={disclaimer_text as RichTextField}
                            elementType="heading5"
                            mainContentColor={
                              description_text_color
                                ? getColor(description_text_color)
                                    .textColorClass
                                : 'text-SnapGrey500'
                            }
                            iconTooltipColor={colors.SnapDarkBlue}
                          />
                        </span>
                      ) : description && description.length > 0 ? (
                        <PrismicRichText field={description} />
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </SliceWrapper>
    </div>
  );
}

export default StoryWithoutImage;
